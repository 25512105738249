var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"contactSection"}},[_c('v-img',{staticClass:"white--text ",attrs:{"dark":"","src":require('@/assets/Backgrounds/bg-contact.jpg')}},[_c('v-container',{staticClass:"fill-height px-4 py-12"},[_c('v-responsive',{staticClass:"d-flex align-start text-position",attrs:{"height":"100%","width":"100%"}},[_c('v-spacer',{staticClass:"contact--head--spacer"}),_c('v-container',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mb-responsive",attrs:{"d-flex":"","flex-md-row":"","flex-sm-column":"","md-12":"","xl-6":"","align-center":"","justify-center":""}},[_c('div',{staticClass:"logo-contact",attrs:{"data-aos":"fade-right","data-aos-duration":"1500","data-aos-delay":"100"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"width":"50%","src":require('@/assets/logos-orange.png')}})],1),_c('div',{staticClass:"form-contacto",attrs:{"data-aos":"fade-left","data-aos-duration":"1500","data-aos-delay":"100","data-aos-offset":"300"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"f-contact",on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('validation-provider',{staticClass:"d-flex flex-row",attrs:{"name":"Nombre","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"iconUser icons"},[_c('img',{attrs:{"src":require('@/assets/icons/User.png'),"alt":""}})]),_c('v-text-field',{attrs:{"counter":15,"error-messages":errors,"label":"Nombre *","required":""},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}})]}}],null,true)}),_c('validation-provider',{staticClass:"d-flex flex-row",attrs:{"name":"Teléfono","rules":"max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"iconPhone icons"},[_c('img',{attrs:{"src":require('@/assets/icons/Phone.png'),"alt":""}})]),_c('v-text-field',{attrs:{"counter":13,"error-messages":errors,"label":"Teléfono","type":"number"},model:{value:(_vm.contact.phone),callback:function ($$v) {_vm.$set(_vm.contact, "phone", $$v)},expression:"contact.phone"}})]}}],null,true)}),_c('validation-provider',{staticClass:"d-flex flex-row",attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"iconEmail icons"},[_c('img',{attrs:{"src":require('@/assets/icons/Email.png'),"alt":""}})]),_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email *","required":""},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}})]}}],null,true)}),_c('validation-provider',{staticClass:"d-flex flex-row align-start",attrs:{"name":"message"}},[_c('div',{staticClass:"iconMessage icons"},[_c('img',{attrs:{"src":require('@/assets/icons/Message.png'),"alt":""}})]),_c('v-textarea',{attrs:{"label":"Mensaje"},model:{value:(_vm.contact.message),callback:function ($$v) {_vm.$set(_vm.contact, "message", $$v)},expression:"contact.message"}})],1),_c('validation-provider',{attrs:{"rules":"required","name":"checkbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"error-messages":errors,"value":"1","label":"Acepto los términos y condiciones","type":"checkbox","required":""},model:{value:(_vm.contact.checkbox),callback:function ($$v) {_vm.$set(_vm.contact, "checkbox", $$v)},expression:"contact.checkbox"}})]}}],null,true)}),_c('div',{staticClass:"btn-form"},[_c('v-btn',{staticClass:"mr-4 btn-submit",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" Enviar ")])],1),_c('v-alert',{staticClass:"text-center mt-6",attrs:{"color":_vm.alert.color,"dark":"","dismissible":""},model:{value:(_vm.alert.state),callback:function ($$v) {_vm.$set(_vm.alert, "state", $$v)},expression:"alert.state"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")])],1)]}}])})],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }