<template>
    <section id="contactSection" class="">
        <v-img
            dark
            :src="require('@/assets/Backgrounds/bg-contact.jpg')"
            class="white--text "
        >
            <v-container class="fill-height px-4 py-12">
                <v-responsive
                    class="d-flex align-start text-position"
                    height="100%"
                    width="100%"
                >
                    <v-spacer class="contact--head--spacer"/>
                    <v-container>
                        <v-layout wrap>
                            <v-flex 
                                d-flex flex-md-row flex-sm-column md-12 xl-6 align-center justify-center
                                class="mb-responsive"
                            >
                                <div
                                    class="logo-contact"
                                    data-aos="fade-right"
                                    data-aos-duration="1500"
                                    data-aos-delay="100"
                                >
                                    <v-img 
                                        width="50%" 
                                        class="mx-auto"
                                        :src="require('@/assets/logos-orange.png')"
                                    />
                                </div>
                                <div class="form-contacto"
                                    data-aos="fade-left"
                                    data-aos-duration="1500"
                                    data-aos-delay="100"
                                    data-aos-offset="300"
                                >
                                    <validation-observer
                                        ref="observer"
                                        v-slot="{ invalid }"
                                    >
                                        <form class="f-contact" @submit.prevent="submit()">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Nombre"
                                                rules="required|max:25"
                                                class="d-flex flex-row"
                                            >
                                                <div class="iconUser icons"><img :src="require('@/assets/icons/User.png')" alt=""></div>
                                                <v-text-field
                                                    v-model="contact.name"
                                                    :counter="15"
                                                    :error-messages="errors"
                                                    label="Nombre *"
                                                    required
                                                ></v-text-field>
                                            </validation-provider>
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Teléfono"
                                                rules="max:13"
                                                class="d-flex flex-row"
                                            >
                                                <div class="iconPhone icons"><img :src="require('@/assets/icons/Phone.png')" alt=""></div>
                                                <v-text-field
                                                    v-model="contact.phone"
                                                    :counter="13"
                                                    :error-messages="errors"
                                                    label="Teléfono"
                                                    type="number"
                                                ></v-text-field>
                                            </validation-provider>
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="email"
                                                rules="required|email"
                                                class="d-flex flex-row"
                                            >
                                                <div class="iconEmail icons"><img :src="require('@/assets/icons/Email.png')" alt=""></div>
                                                <v-text-field
                                                    v-model="contact.email"
                                                    :error-messages="errors"
                                                    label="Email *"
                                                    required
                                                ></v-text-field>
                                            </validation-provider>
                                            <validation-provider
                                                name="message"
                                                class="d-flex flex-row align-start"
                                            >
                                                <div class="iconMessage icons"><img :src="require('@/assets/icons/Message.png')" alt=""></div>
                                                <v-textarea label="Mensaje" v-model="contact.message"></v-textarea>
                                            </validation-provider>
                                            <validation-provider
                                                v-slot="{ errors }"
                                                rules="required"
                                                name="checkbox"
                                            >
                                                <v-checkbox
                                                    v-model="contact.checkbox"
                                                    :error-messages="errors"
                                                    value="1"
                                                    label="Acepto los términos y condiciones"
                                                    type="checkbox"
                                                    required
                                                ></v-checkbox>
                                            </validation-provider>

                                            <div class="btn-form">
                                                <v-btn
                                                    class="mr-4 btn-submit"
                                                    type="submit"
                                                    :disabled="invalid"
                                                >
                                                    Enviar
                                                </v-btn>
                                            </div>
                                            <v-alert v-model="alert.state" class="text-center mt-6" :color="alert.color" dark dismissible>
                                                {{ alert.message }}
                                            </v-alert>
                                        </form>
                                    </validation-observer>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-responsive>
            </v-container>
        </v-img>
    </section>
</template>

<script>
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import emailjs from 'emailjs-com';

setInteractionMode('eager')

extend('required', {
    ...required,
    message: 'El campo {_field_} no puede estar vacío',
})

extend('max', {
    ...max,
    message: '{_field_} No puede tener más de {length} caracteres',
})

extend('email', {
    ...email,
    message: 'Formato de email inválido',
})

export default {
    name: 'SectionHomeHeader',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            contact: {
                name: '',
                email: '',
                phone: '',
                message: '',
                checkbox: null,
            },
            alert: {
                state: false,
                color: '',
                message: ''
            },
            message: {
                sendSuccess: '* El mensaje ha sido enviado exitosamente',
                sendError: 'No se pudo enviar el mensaje. Vuelve a intentarlo más tarde'
            }
        }
    },
    created() {

    },
    methods: {
        submit () {
            this.save()
            this.$refs.observer.validate()
        },
        clear () {
            this.name = ''
            this.email = ''
            this.phone = ''
            this.message = ''
            this.checkbox = null
            this.$refs.observer.reset()
        },
        save() {
            const templateParams = {
                    name: this.contact.name,
                    email: this.contact.email,
                    phone: this.contact.phone,
                    message: this.contact.message
                },
                serviceID = 'service_7oj4aqj',
                templateID = 'template_d275ewj',
                userID = 'user_vSfGqToM7T4osI0orRze5';
            
            // console.log(templateParams)
            emailjs.send(serviceID, templateID, templateParams, userID)
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.templateParams);
                    this.alert = {
                        state: true,
                        color: 'success',
                        message: `${this.message.sendSuccess}`
                    }
                }, (err) => {
                    console.log('FAILED...', err);
                    this.alert = {
                        state: true,
                        color: 'error',
                        message: `${this.message.sendError} codigo ${err}`
                    }
                });
        }
    },
    mounted() {
        // this.save()
    },
}
</script>

<style lang="scss">
#contactSection {
    .contact--head--spacer { margin: 31vw 0; }
    .logo-contact { width: 50%;}
    .form-contacto {
        width: 50%;
        .f-contact {
            .icons {
                display: flex;
                align-items: center;
                margin-right: 1vw;
            }
            .iconPhone, .iconEmail, .iconMessage { width: 6%; }
            .iconMessage { margin-top: 2vw; }
            width: 60%;
            input, textarea {
                &::placeholder {
                    font-family: FontAwesome, 'Lato', sans-serif;
                }
                background-color: #2D333B;
            }
            .btn-form {
                display: flex;
                justify-content: flex-end;
                .btn-submit { background-color: #F35E22 !important; }
                .btn-clean { background-color: #2D333B !important; }
            }
            label.v-label { left: 10px !important; }
            .v-alert {
                background-color: transparent !important;
                .v-alert__content { color: #F35E22; font-weight: 700; }
                button { display: none; }
            }
        }
    }

    @media (max-width: 760px) {
        .mb-responsive {
            flex-direction: column;
            .logo-contact { width: 100%; }
            .form-contacto {
                display: flex;
                justify-content: center;
                width: 100%;
                .f-contact {
                    width: 100%;
                }
            }
        }

    }
}
</style>